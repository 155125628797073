import axios from "axios";

export const apiRequest = async (method, url, payload) => {
    // console.log("api data :" + method + "url : " + url + " data :" + JSON.stringify(payload));
  try {
    const response = await axios({
      method,
      url,
      data: payload,
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
   
    });

    const ans = await response.data
    return ans
    // return response.data;
  } catch (error) {
    console.log("api error : " + error);
    return error;
  }
};

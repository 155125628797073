import React from "react";

function Spinner() {
  return (
    <div className="fixed inset-0 bg-black opacity-60 flex justify-center items-center z-[9999] ">
      <div className="w-10 h-10 border-1 border-red-800 border-solid border-t-transparent rounded-full animate-spin">
        {/* <h6 className="text-white text-center">A</h6> */}
      </div>
      <div className="w-10 h-10 border-1 border-blue-800 border-solid border-t-transparent rounded-full animate-spin">
        {/* <h6 className="text-white text-center">S</h6> */}
      </div>
      <div className="w-10 h-10 border-1 border-green-800 border-solid border-t-transparent rounded-full animate-spin">
        {/* <h6 className="text-white text-center">C</h6> */}
      </div>
      <div className="w-10 h-10 border-1 border-orange-800 border-solid border-t-transparent rounded-full animate-spin">
        {/* <h6 className="text-white text-center">E</h6> */}
      </div>
      <div className="w-10 h-10 border-1 border-yellow-800 border-solid border-t-transparent rounded-full animate-spin">
        {/* <h6 className="text-white text-center">N</h6> */}
      </div>
      <div className="w-10 h-10 border-1 border-pink-200 border-solid border-t-transparent rounded-full animate-spin">
        {/* <h6 className="text-white text-center">T</h6> */}
      </div>
    </div>
  );
}

export default Spinner;

import React, { useEffect, useState } from "react";
import NavBar from "../../../components/NavBar";
import { QRCode, Form, Input, Button, Image, Divider, message } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loadersSlice";
import { getProfileById } from "../../../apicalls/profiles";

function PublicProfile() {
  const params = useParams();

  const dispatch = useDispatch();

  const [savecontact, setsavecontact] = useState();

  const [expdate, setexpdate] = useState();



  const getData = async () => {
    try {
      dispatch(setLoading(true));
      //   console.log("params._id" + JSON.stringify(params));
      const response = await getProfileById(params.id);

      if (response.success) {
        dispatch(setLoading(false));
        //  console.log("params._id" + JSON.stringify(response));
        // console.log("params._id" + (response.data.owner.trialenddate));
        setProfileVal(response.data);

        // var todaysDate = new Date();
        // console.log("Today : ", todaysDate.toString() + "  exp ", response.data.owner.trialenddate);

        setexpdate(response.isExpired);
      } else {
        dispatch(setLoading(false));
        throw new Error(response.message);
      }
    } catch (error) {
      message.error(error.message);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [profileval, setProfileVal] = useState({
    firstName: "xxxx",
    lastName: "xxxx",
    companyName: "xxxx",
    designation: "xxxx",
    website: "xxxx.com",
    email: "xxxx",
    trialenddate: "",
    facebook: "https://www.facebook.com/",
    tweeter: "xxxx",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com/",
    mobileno: "xxxx",
    imageurl:
      "https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg",
  });

  const qrloc = useLocation();

  const  SavetoContact = () => {
    // var contact = {
    //   name: "John Smith",
    //   phone: "555-555-5555",
    //   email: "john@example.com",
    // };

    dispatch(setLoading(true));
    const urlofcard = process.env.REACT_APP_DOMAIN + qrloc.pathname;



    var vcard1 =
      "BEGIN:VCARD\nVERSION:3.0\nFN:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      "\nN:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      "\nTEL;TYPE=work,voice:" +
      profileval?.mobileno +
      "\nURL:" +
      urlofcard +
      "\nEMAIL;TYPE=work:" +
      profileval?.email +
      "\nTITLE:" +
      profileval?.designation +
      "PROFILE:VCARD" +
      "\nORG:" +
      profileval?.companyName +
      "\nEND:VCARD";

    var todaysDate = new Date();
    //        console.log("Today : ", todaysDate.toString() + "  exp ", response.data.owner.trialenddate);

    var vcard =
      "BEGIN:VCARD" +
      "\nVERSION:3.0" +
      "\nREV:" +
      todaysDate.toString() +
      "\nN;CHARSET=utf-8:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      ";;;" +
      "\nFN;CHARSET=utf-8:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      "\nORG;CHARSET=utf-8:" +
      profileval?.companyName +
      "\nTITLE;CHARSET=utf-8:" +
      profileval?.designation +
      "\nEMAIL;TYPE=work:" +
      profileval?.email +
      "\nTEL;PREF;WORK:" +
      profileval?.mobileno +
      "\nTEL;WORK:" +
      profileval?.mobileno +
      "\nNOTE:APP Developed by - Ascent Software Solutions \n for enquiry mail us at ascentnext@gmail.com" +
      "\nPHOTO;TYPE=JPEG;VALUE=URI:" +
      profileval?.imageurl +
      "\nURL:" +
      urlofcard +
      "\nEND:VCARD";

    // var blob = new Blob([vcard], { type: "text/vcard" });
    // var url = URL.createObjectURL(blob);

    const blob = new Blob([vcard], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);



    setsavecontact(url);



     const newLink = document.createElement('a');
     newLink.href = url;

     newLink.download = profileval?.firstName + '.vcf';
     newLink.textContent = profileval?.firstName;
     document.body.appendChild(newLink);


     newLink.click();

     // Remove the link after triggering the download
    document.body.removeChild(newLink);
    
    // Revoke the object URL
    window.URL.revokeObjectURL(url);



    // const newLink = document.createElement("a");
    // newLink.download = contact.name + ".vcf";
    // newLink.textContent = contact.name;
    // newLink.href = url;

    // newLink.click();

    dispatch(setLoading(false));


    // const shatedata = {
    //   title: "New Contacts",
    //   text: "Save contacts",
    //   files: [
    //     new File([blob], "newcontact.vcf", {
    //       type: "text/vcard",
    //     }),
    //   ],
    // };

    // if (navigator.share && navigator.canShare(shatedata)) {
    //   navigator
    //     .share({
    //       title: "New Contacts",
    //       text: "Save contacts",
    //       files: [
    //         new File([blob], "newcontact.vcf", {
    //           type: "text/vcard",
    //         }),
    //       ],
    //     })
    //     .then(() => {});
    // } else {
    //   const newLink = document.createElement("a");
    //   newLink.download = profileval?.firstName + ".vcf";
    //   newLink.textContent = profileval?.firstName;
    //   newLink.href = url;

    //   newLink.click();

    //   // window.close();
    // }





  };

  const [shorturl, setShorturl] = useState();

  const ProfileData = () => {
    let wametext = `https://wa.me/${profileval?.mobileno}?text=Hello ${profileval?.firstName}%0 I Have come across your profile through Know_Me App`;

    return (
      <div class="flex m-10 flex-col  justify-between items-center rounded-md relative shadow-lg w-full  lg:w-1/3 overflow-hidden z-30 md:p-10  bg-white">
        <div className="h-[26%] bg-blue-500  absolute w-full z-30 -m-10"></div>
        <div className="flex w-full">

        {/* <div className="h-14 bg-white absolute w-14 z-50 -m-10 justify-end flex items-end">
        <img
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            alt="know_me profile"
            // class="imgclass brightness-125"
            class=" brightness-125 object-fit w-full h-full  shadow-xl bg-transparent object-center"

            

          />

        </div> */}

        </div>
        {/* <div class="profileimage relative z-40  mt-2"> */}
        <div class="mt-5 z-50 w-[85%] h-70 flex justify-center p-2 bg-transparent">
 
          <img
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            alt="know_me profile"
            // class="imgclass brightness-125"
            class=" brightness-125 object-cover rounded-3xl w-full h-full  shadow-xl bg-transparent object-center"

            

          />

        
        
        </div>


        <div class="workdetails ">
          <span class="username">
            {profileval?.firstName} {profileval?.lastName}
          </span>
          
          <span class="font-light text-sm">{profileval?.designation}</span>
          
          {profileval?.designation && <span class="companyname">{profileval?.companyName}</span>}
          {profileval?.mobileno && <span class="Mobile font-semibold mt-1">
            Mobile : {profileval?.mobileno}
          </span>}
        </div>

        <div class="webdetails">
          {profileval?.website && <div class="website">
                  <a href={profileval?.website} class="weburl">
            Website : {profileval?.website}
            </a>
          </div>}

          {profileval?.email && <div class="email mt-2">
      
            <span class="userwork">Email : {profileval?.email}</span>
          </div>}
        </div>

        <div class="socialtabs mt-5">
          
          {profileval?.facebook && <a
            href={profileval?.facebook}
            target="_blank"
            class="link bg-[#1877F2]"
          >
            <i class="ri-facebook-fill "></i>
          </a>}


          {profileval?.instagram && <a
            href={profileval?.instagram}
            target="_blank"
            class="link bg-[#E4405F] "
          >
            <i class="ri-instagram-fill"></i>
          </a>}


          {profileval?.linkedin && <a
            href={profileval?.linkedin}
            target="_blank"
            class="link bg-[#0A66C2]"
          >
            <i class="ri-linkedin-fill"></i>
          </a>}

          {/* <a
            href={savecontact}
            class="link ring-1 ring-[#000000]"
            onClick={SavetoContact}
            target="_blank"
            // download={savecontact}
            download
          >
            <i class="ri-file-download-line bg-red-800"></i>
          </a>
 */}

        </div>
        <div class="cta flex w-full  items-center justify-center">
          <a
            href={savecontact}
            class="bg-blue-600 shadow-xl p-3 px-8 text-white  rounded-md justify-center flex flex-row gap-2 items-center  font-light"
            onClick={SavetoContact}
            target="_blank"
            // download={savecontact}
            download
          >
            Save to contacts
            <i class="ri-file-download-line animate-bounce"></i>
          </a>


        </div>



        <div class="cta flex w-full p-2">
          <a href={"tel:" + profileval.mobileno} class="bg-blue-600 p-2 w-1/2 text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline">
            Call
            <i class="ri-phone-fill animate-pulse"></i>
          </a>{" "}
          <a
            href={`https://wa.me/${profileval?.mobileno}?text=Hello ${profileval?.firstName}  We have come across your profile through Know_Me App`}
            class="bg-green-600 p-2 w-1/2 text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline"
          >
            WhatApp<i class="ri-whatsapp-fill animate-pulse"></i>
          </a>{" "}
        </div>
        <div class="footer">
          <a href="#" class="create">
            <span>Scan Qr code</span>
            <i class="ri-arrow-down-line animate-bounce"></i>
          </a>

            
          <span class="poweredby text-center flex items-center justify-center w-full text-blue-700">
            <a href="www.ascentnx.dev" className="no-underline text-black p-2">
            Powered by | Ascent Next | Since 2010
            </a>
          </span>
        </div>
      </div>
    );
  };

  const Profile1 = () => {
    // console.log("env : ", process.env.REACT_APP_DOMAIN);
    let qrloc = useLocation();
    // const shortUrl = require("turl");
    // shortUrl
    //   .shorten(qrloc)
    //   .then((res) => {
    //     setShorturl(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // shortUrl.short({ qrloc }, function (err, url) {
    //   console.log("url", url);
    //   setShorturl(url);
    // });

    const [usermobile, setusermobile] = useState("")


    const handleChange = (e) => {
      // console.log("mobile no of user ",e.target.value);
       setusermobile(e.target.value)
    }

    
    return (
      <div className="bg-neutral-100 w-screen">
        {!expdate && (
          <div class="flex flex-col justify-center items-center w-full p-4 bg-neutral-100">
            {/* {expdate ? <h1>Profile Expired</h1> : <h1>Not Expired</h1>} */}
            {/* <h1>Checking Expiry Date{profileval?.trialenddate}</h1> */}

            {<ProfileData />}
            <div className="qrData p-10">
              <div className="justify-center flex items-center flex-wrap  flex-col gap-4">
                <QRCode
                  value={process.env.REACT_APP_DOMAIN + qrloc.pathname}
                ></QRCode>
                <h1 className="text-sm font-thin">Scan to view profile</h1>
              </div>
              <div className="mt-10 gap-4 flex flex-col p-2 " >
                <h3 className="font-light text-sm">Enter Destination Mobile no with country code</h3>
                <input value={usermobile} type="number"  placeholder="Mobile no with Country Code" className="w-full p-2 text-black font-bold"  onChange={handleChange}></input>
                <a
                target="_blank"
                href={`https://wa.me/${usermobile}?text=Hello check out my profile from Know_Me App @ %0a ${process.env.REACT_APP_DOMAIN + qrloc.pathname} `}
                class="bg-green-600 p-2  text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline"
              >
                WhatApp Profile<i class="ri-whatsapp-fill animate-pulse"></i>
              </a>{" "}
              </div>
            </div>
          </div>
        )}
        {expdate && <div className="flex justify-center items-center">
          <h1 className="text-2xl font-thin">Profile Expired ! Renew your Subscription</h1></div>}
      </div>
    );
  };

  return (
    <div className="mainPageAct mt-33 lg:mt-20 ">
      <NavBar></NavBar>
      {/* <Tabs defaultActiveKey="2">
        <Tabs.TabPane tab="Profile" key="1">
          <CompanyProfiles />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Preview" key="2">
          <ProfilePage />
        </Tabs.TabPane>
      </Tabs> */}

      <div className="justify-center flex  bg-neutral-100 w-screen">{<Profile1 />}</div>
    </div>
  );
}

export default PublicProfile;

import { message, Button } from "antd";
import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { GetLoggedInUser } from "../apicalls/users";
import { useDispatch, useSelector } from "react-redux";
import { SetUser } from "../redux/userSlice";
import { setLoading } from "../redux/loadersSlice";

function ProtectedPage({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.users);

  // const [user, SetUser] = useState(null);

  const getUser = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetLoggedInUser();
      dispatch(setLoading(false));
      if (response.success) {
        // console.log("response.data : " + JSON.stringify(response.data));

        dispatch(SetUser(response.data));
        // SetUser(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      message.error(error.message);
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUser();
    } else {
      // navigate("/login");
      navigate("/intro");
    }
  }, []);

  return (
    user && (
      <div className="appBg h-screen">
        <div className=" flex flex-col md:flex-row w-full p-2.5 gap-4 items-center justify-between  border-y-gray-100 bg-white shadow-md ">
        <a
            href="/"
            className="text-2xl font-semibold text-primarytextcolor no-underline  p-1 rounded pl-4 pr-4"
          >
            know<span className="text-3xl text-red-700">Me</span>
          </a>
          <ul className="flex gap-10 decoration-slice list-none mr-10">
            {/* <li>
              <a href="/pricing" className="no-underline text-black">
                Pricing
              </a>
            </li> */}
            <li>
              <a href="/profile" className="no-underline text-black">
                Know_Me Accounts
              </a>
            </li>

            {user?.roll === "Admin" && (
            <li>
            <a href="/qrprofile" className="no-underline text-black">
              QR Accounts
            </a>
          </li>
            
            )}


            {user?.roll === "Admin" && (
              <li>
                <a href="/register" className="no-underline text-black">
                  Register New Profile
                </a>
              </li>
              
            )}
          </ul>
          <div className="flext items-center  bg-blue-500 px-5 py-2 rounded">
            <span
              className="mr-5 cursor-pointer text-white"
              onClick={() => navigate("/profile")}
            >
              {user?.firstName}
            </span>
            <i className="ri-notification-line "></i>
            <i
              className="ri-logout-box-r-line mx-5 cursor-pointer text-white"
              onClick={() => {
                localStorage.removeItem("token");
                message.success(
                  "Logout Successful Thank you for using KnowMe.."
                );
                navigate("/login");
              }}
            >Logout</i>
          </div>
        </div>
        <div className="mt-2 bg-primary">{children}</div>
      </div>
    )
  );
}

/// command line for nodemon --ignore ./client

export default ProtectedPage;

import React, { useEffect } from "react";
import { Form, Input, Button, Image, Divider, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import loginimage from "../../assets/login.svg";
import { LoginUser } from "../../apicalls/users";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import NavBar from "../../components/NavBar";

function Login() {
  const { loading } = useSelector((state) => state.loaders);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const onFinish = async (values) => {
    // console.log(values);
    try {
      dispatch(setLoading(true));
      const response = await LoginUser(values);
      // console.log("response " + JSON.stringify(response));
      dispatch(setLoading(false));

      if (response.success) {
        localStorage.setItem("token", response.data);
        message.success(response.message);
        window.location.href = "/";
      } else {
        message.error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      message.error(error.message);
    }

    
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  return (
    <div className=" h-screen flex justify-center items-center flex-col">
      <NavBar />
      <div className="flex justify-center items-center p-5 shadow-lg w-[380px]">
        <div >
          <h3 className="mb-5  justify-center text-2xl font-light">
            Login to knowMe{" "}
           
          </h3>
          <Divider />
          <Form layout="vertical" onFinish={onFinish}
          size="middle">
            <Form.Item
              label="email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email id can not be blank",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="password"
              name="password"
              rules={[
                {
                  required: true,
                  message:
                    "Password can not be blank and minimum 6 digits required",
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              className="bg-blue-600"
            >
              {loading ? "Loading .." : "Login"}
            </Button>

            <div className="flex justify-center mt-5">
              <span>
                Don't have an Account ? <Link to={"/register"}>Register</Link>
              </span>
            </div>
            <div className="flex justify-center mt-5">
              <span className="">
                 <Link to={"/forgotpassword"} className="text-red-500 text-sm underline">Forgot Password ?</Link>
              </span>
            </div>


            <div className="flex justify-center mt-5">
              <span>
                Powered by :{" "}
                <Link to={"http://ascentnx.com"}>Ascent Next | Since 2010</Link>
              </span>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;

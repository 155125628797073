import React, { useEffect } from "react";
import { Form, Input, Button, Image, Divider, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import loginimage from "../../assets/login.svg";
import { RegisterUser } from "../../apicalls/users";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";

function Register() {
  const { loading } = useSelector((state) => state.loaders);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onFinish = async (values) => {
    dispatch(setLoading(true));
    // console.log(values);
    try {
      const response = await RegisterUser(values);
      dispatch(setLoading(false));
      // console.log("response : " + JSON.stringify(response));
      if (response.success === true) {
        message.success(response.message);
        navigate("/login");
      } else {
        message.error(response.message);
        // console.log("Sucess false : " + JSON.stringify(response));
      }
    } catch (error) {
      dispatch(setLoading(false));
      message.error(error.message);
      throw new Error(error.message);
    }
  };

//   useEffect(() => {
//     if (localStorage.getItem("token")) {
//       navigate("/");
//     }
//   }, []);

  return (
    <div>
      <div className="flex justify-center items-center h-screen w-screen bg-secondery mt-10">
        <div className="w-[400px] items-center bg-white p-5 rounded">
          <h3 className="mb-5  justify-center">
            Register to{" "}
            <spam className="text-primarytextcolor text-2xl ml-1 underline">
              kNOW ME
            </spam>{" "}
          </h3>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Field can not be blank",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Field can not be blank",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Field can not be blank",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message:
                    "Field can not be blank and minimum 6 digits required",
                  min: 6,
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>

            <Button type="primary" htmlType="submit" block loading={loading}>
              {loading ? "Loading .." : "Register"}
            </Button>

            <div className="flex justify-center mt-5">
              <span>
                Already have an Account ? <Link to={"/login"}>Login</Link>
              </span>
            </div>
            <div className="flex justify-center mt-5">
              <span>
                Powered by :{" "}
                <Link to={"http://ascentnx.com"}>Ascent Next | Since 2010</Link>
              </span>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Register;

const { apiRequest } = require(".");

export const createProfile = async (payload) =>
  apiRequest("post", "/api/profiles/createProfile", payload);

export const getAllProfile = async (payload) =>
  apiRequest("post", "/api/profiles/getProfiles", payload);

export const updateProfile = async (payload) =>
  apiRequest("post", "/api/profiles/updateProfile", payload);

export const deleteProfile = async (objectID) =>
  apiRequest("post", "/api/profiles/deleteProfile", { _id: objectID });

// getProjectByRoll
export const getProjectbyRoll = async (userId) =>
  apiRequest("post", "/api/projects/getProjectByRoll", userId);

//getProjectById

export const getProfileById = async (projectid) =>
  apiRequest("post", "/api/profiles/getProfilebyId", { _id: projectid });

// Add Member to Project

export const addProjectMember = async (data) =>
  apiRequest("post", "/api/projects/addProjectMember", data);

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProjectById } from "../../apicalls/profiles";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import { Divider, Tabs, message } from "antd";
import { getDateFormat } from "../../utils/helpers";
import Tasks from "./Tasks";
import Members from "./Members";

function ProjectInfo() {
  const params = useParams();
  const [project, setproject] = useState();
  const [loggedinUserRole, setloggedinUserRole] = useState();

  const { user } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  // const getData = async () => {
  //   try {
  //     dispatch(setLoading(true));
  //     //      console.log("params._id" + JSON.stringify(params));
  //     const response = await getProjectById(params.id);

  //     if (response.success) {
  //       dispatch(setLoading(false));
  //       setproject(response.data);
  //       const currentUser = response.data.members.find(
  //         (member) => member.user._id === user._id
  //       );
  //       setloggedinUserRole(currentUser.role);
  //     } else {
  //       dispatch(setLoading(false));
  //       throw new Error(response.message);
  //     }
  //   } catch (error) {
  //     message.error(error.message);
  //     dispatch(setLoading(false));
  //   }
  // };

  useEffect(() => {
    // getData();
  }, []);

  return (
    project && (
      <div>
        <div className="lg:flex justify-between items-center">
          <div>
            <h1 className="text-black text-xl uppercase">
              {" "}
              Project : {project.name}
            </h1>{" "}
            <p className="mt-2 text-m w-500">{project.desc}</p>
            <h5 className="mt-2 text-semibold w-500 uppercase ">
              Role : {loggedinUserRole}
            </h5>
          </div>

          <div className="mt-5 xl:mt-0 lg:mt-0">
            <h4 className="uppercase flex gap-5 font-semibold">
              Owner <span>{project.owner.firstName}</span>
            </h4>
            <h5 className="flex gap-5 mt-2">
              CreatedAt <span>{getDateFormat(project.createdAt)}</span>
            </h5>
            <h5 className="mt-2 uppercase bg-yellow-500 text-center border border-solid border-redius p-3 rounded lg:p-1">
              {project.status}
            </h5>
          </div>
        </div>

        <Divider className="border border-solid" />
        <Tabs defaultActiveKey="1" animated="true">
          <Tabs.TabPane tab="Tasks" key="1">
            <Tasks />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Members" key="2">
            {/* <Members project={project} reloadData={getData} /> */}
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  );
}

export default ProjectInfo;

import React, { useEffect, useRef, useState } from "react";
import { Tabs, ColorPicker, Table } from "antd";
import CompanyProfiles from "./CompanyProfiles";
import { PlusOutlined } from "@ant-design/icons";
import ProfilePage from "./CompanyProfiles/ProfilePage";

import { Form, Input, Button, Image, Divider, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import loginimage from "../../assets/login.svg";
import { RegisterUser } from "../../apicalls/users";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import ProfileForm from "./CompanyProfiles/ProfileForm";
import {
  deleteProfile,
  getAllProfile,
  getProfileById,
} from "../../apicalls/profiles";
import { getDateFormat } from "../../utils/helpers";

function Profile() {
  const dispatch = useDispatch();
  const formRef = useRef();

  const navigate = useNavigate();

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profiles, setprofiles] = useState([]);
  const [profileCount, setprofileCount] = useState(0);

  const [show, setshow] = useState(false);

  const urlofcard = process.env.REACT_APP_DOMAIN 

  const [showAlert, setShowAlert] = useState({
    showAlertBox: false,
    setRecordIndex: "",
    setRecordName: "",
  });

  const { user } = useSelector((state) => state.users);

  


  const columns = [
    {
      title: "Actions",
      render: (text, record) => {
        return (
          <div className="flex gap-5">
            <i
              class="ri-pencil-line cursor-pointer"
              onClick={() => {
                setSelectedProfile(record);
                setshow(true);
              }}
            ></i>
            <i
              class="ri-delete-bin-line cursor-pointer"
              onClick={() => {
                setShowAlert({
                  showAlertBox: true,
                  setRecordIndex: record._id,
                  setRecordName: record.name,
                });
                deleteProfileData(record._id);
              }}
            ></i>

            <i
              class="ri-eye-line text-green-500 cursor-pointer"
              onClick={() => {
                 navigate("/profileview/" + record._id);
                // navigate("/"+ urlofcard + "/profileview/" + record._id)
              }}
            ></i>
          </div>
        );
      },
    },

    {
      title: "Company",
      dataIndex: "companyName",
    },
    {
      title: "Name",
      render: (value, record) =>
        record.firstName.toUpperCase() + " " + record.lastName.toUpperCase(),
    },
    {
      title: "email",
      dataIndex: "email",
    },
    {
      title: "Created By",
      render: (value, record) => record.firstName.toUpperCase(),
    },
    {
      title: "Roll",
      render: (value, record) => record.owner.roll,
    },
    {
      title: "Created On",
      render: (value, record) => getDateFormat(record.createdAt),
    },
    {
      title: "Expired On",
      render: (value, record) => getDateFormat(record.owner.trialenddate),
    },
  ];

  const [profileval, setProfileVal] = useState({
    firstName: "xxxx",
    lastName: "xxxx",
    companyName: "xxxx",
    designation: "xxxx",
    website: "xxxx.com",
    email: "xxxx",
    facebook: "xxxx",
    tweeter: "xxxx",
    insta: "xxxx",
    linkedin: "xxxx",
    mobileno: "xxxx",
  });

  const getData = async () => {
    //  console.log("user id: ", user);
    try {
      dispatch(setLoading(true));
      const response = await getAllProfile({ owner: user._id });

      if (response.success) {
        // console.log("response : " + JSON.stringify(response));
        setprofiles(response.data);
        setprofileCount(response.count);
        // /console.log("Project Data : ", projects);
        dispatch(setLoading(false));
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      message.error(error.message);
      dispatch(setLoading(false));
    }
  };

  // Delete Data

  const deleteProfileData = async (id) => {
    try {
      dispatch(setLoading(true));
      const response = await deleteProfile(id);

      if (response.success) {
        dispatch(setLoading(false));
        getData();
        message.success(response.message);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      message.error(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const ProfileData = () => {
    return (
      <div class="card flex m-2 ">
        <div class="profileimage">
          <img
            src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            alt=""
            class="imgclass"
          />
        </div>
        <div class="workdetails">
          <span class="username">
            {profileval?.firstName} {profileval?.lastName}
          </span>
          <span class="userwork">{profileval?.designation}</span>
          <span class="companyname">{profileval?.companyName}</span>
        </div>
        <div class="webdetails">
          <div class="website">
            <label for="website">Website : </label>
            <a href={profileval?.website} class="weburl">
              {profileval?.website}
            </a>
          </div>

          <div class="email">
            <label for="Email">Email :</label>
            <span class="userwork">{profileval?.email}</span>
          </div>
        </div>

        <div class="socialtabs">
          <a href="#" class="link bg-[#1877F2]">
            <i class="ri-facebook-fill "></i>
          </a>
          <a href="#" class="link bg-[#E4405F] ">
            <i class="ri-instagram-fill"></i>
          </a>
          <a href="#" class="link bg-[#1DA1F2]">
            <i class="ri-twitter-fill"></i>
          </a>
          <a href="#" class="link bg-[#0A66C2]">
            <i class="ri-linkedin-fill"></i>
          </a>
        </div>
        <div class="cta">
          <button class="btn text-white">
            Call
            <i class="ri-phone-fill"></i>
          </button>
          <button class="btn bg-slate-200">
            WhatApp<i class="ri-whatsapp-fill"></i>
          </button>
        </div>
        <div class="footer">
          <a href="#" class="create">
            <span>Create Your Profile for free</span>
            <i class="ri-arrow-right-line"></i>
          </a>

          <span class="poweredby">
            Poweredby | Ascent Software Solutions | Since 2010
          </span>
        </div>
      </div>
    );
  };

  const Profile1 = () => {
    return <div class="templatetest  bg-white ">{<ProfileData />}</div>;
  };
  var firstProfile = profiles[0];

  return (
    <div className="mt-40  ml-5 mr-5 flex flex-col lg:mt-20 lg:ml-10 lg:mr-10">

      <div className="flex justify-between mb-10 ">
        <h5 className="text-2xl font-normal">My Profiles</h5>

        <Button
          type="default bg-green-500 text-white ring-1 ring-black"
          onClick={() => {
             navigate("/profileview/" + firstProfile._id);
            
          }}        >
          View Profile
        </Button>

        <Button
          icon={<PlusOutlined />}
          // disabled={user?.roll === "Admin" || profileCount === 1}
          // disabled={user?.roll !== "Admin" && profileCount === 1}
          disabled={user?.roll !== "Admin" && user?.profilecount <= profileCount}
          type="default bg-blue-500 text-white"
          onClick={() => {
            setSelectedProfile(null);
            setshow(true);
          }}
        >
          Add New Profile
        </Button>
      </div>

      <div>
        <Table
          columns={columns}
          dataSource={profiles}
          scroll={{
            x: 500,
          }}
        ></Table>
      </div>
      {show && (
        <ProfileForm
          show={show}
          setshow={setshow}
          reloadData={getData}
          profile={selectedProfile}
        />
      )}
    </div>
  );
}

export default Profile;

import React from "react";
import { useSelector } from "react-redux";

function NavBar() {
  const { user } = useSelector((state) => state.users);

  return (
    <div className="flex w-screen flex-col top-0 left-0 h-20 z-50
      bg-white fixed">
      <div className="flex  h-20 items-center justify-between  shadow-sm px-10">
        <div className="flex items-center">
          <a
            href="/"
            className="text-2xl font-semibold text-primarytextcolor no-underline  p-1 rounded pl-4 pr-4"
          >
            know<span className="text-3xl text-red-700">Me</span>
          </a>
        </div>
        <div className="rightdiv flex gap-10 items-center ">
          {!user && (
            <ul className=" decoration-slice list-none">
              <li>
                <a href="\login" className="no-underline text-black shadow-sm p-4">
                  Login
                </a>
              </li>
            </ul>
          )}{" "}
           <a
            href="/pricing"
            className="p-4 justify-center items-center  bg-blue-600  text-white shadow-lg no-underline flex"
          >
            Free Trial
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavBar;

import { Button, Carousel } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import NavBar from "../../components/NavBar";
import { Link } from "react-router-dom";

function Landing() {
  const { user } = useSelector((state) => state.users);

  const Templates = () => {
    return (
      <div className="bg-primary mt-5 mb-10 ">
        <Carousel autoplay dotPosition="left">
          <div>{<Profile1 />}</div>
          {/* <div>{<Profile1 />}</div>
          <div>{<Profile2 />}</div>
          <div>{<Profile3 />}</div> */}
        </Carousel>
      </div>
    );
  };

  const ProfileData = () => {
    return (
      <div class="card flex w-[100%]">
        <div class="profileimage">
          <img
            src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            alt=""
            class="imgclass"
          />
        </div>
        <div class="workdetails">
          <span class="username">Sanket G.</span>
          <span class="userwork">Founder & Software Developer</span>
          <span class="companyname">Ascent Software Solutions</span>
        </div>
        <div class="webdetails">
          <div class="website">
            <label for="website">Website : </label>
            <a href="https://ascentindia.dev/" class="weburl">
              https://ascentindia.dev/
            </a>
          </div>

          <div class="email">
            <label for="Email">Email :</label>
            <span class="userwork">sanket@ascentindia.dev</span>
          </div>
        </div>

        <div class="socialtabs">
          <a href="#" class="link bg-[#1877F2]">
            <i class="ri-facebook-fill "></i>
          </a>
          <a href="#" class="link bg-[#E4405F] ">
            <i class="ri-instagram-fill"></i>
          </a>
          <a href="#" class="link bg-[#1DA1F2]">
            <i class="ri-twitter-fill"></i>
          </a>
          <a href="#" class="link bg-[#0A66C2]">
            <i class="ri-linkedin-fill"></i>
          </a>
        </div>
        <div class="cta">
          <a href="tel: +919850532468" class="btn text-white">
            Call
            <i class="ri-phone-fill"></i>
          </a>
          <button class="btn">
            WhatApp<i class="ri-whatsapp-fill"></i>
          </button>
        </div>
        <div class="footer">
          {/* <a href="#" class="create">
            <span>Create Your Profile for free</span>
            <i class="ri-arrow-right-line"></i>
          </a> */}

          <span class="text-sm p-4">
            Ascent Software Solutions | Since 2010
          </span>
        </div>
      </div>
    );
  };

  const Profile1 = () => {
    return <div class="template1  bg-transparent ">{<ProfileData />}</div>;
  };

  const Profile2 = () => {
    return <div class="template2  bg-white ">{<ProfileData />} </div>;
  };

  const Profile3 = () => {
    return <div class="template3  bg-white ">{<ProfileData />} </div>;
  };

  const Profile4 = () => {
    return <div class="template4  bg-white ">{<ProfileData />} </div>;
  };

  return (
    <div className=" flex flex-col w-screen ">
    
      <div className="top-0 z-30">      {!user && <NavBar />}</div>

      <div className="landing_hero  bg-primary flex mt-20 pb-4 p-5 md:p-10 flex-col md:flex-row overflow-hidden bg-gradient-to-b from-white to-blue-200">
        <div className="landing_left  pl-2 flex flex-col justify-center gap-8 md:w-[50%] overflow-hidden z-20 ">
          <h1 className="text-5xl uppercase font-semibold mt-1 text-primarytextcolor lg:text-7xl ">
            Meet the Future of Digital{" "}
            <span className="underline">ID</span>
          </h1>
          <h3 className="mt-1 font-light text-primarytextcolor text-3xl">
          Digital Identity for Digital Thinkers
          </h3>
          <p className="mt-1 text-primarytextcolor text-xl font-light">
            Welcome to the future of identification management. Say goodbye
            to outdated physical cards and hello to a seamless digital
            experience. our app streamlines
            your life by securely storing and managing your identification.
            
          </p>
          <p className="underline font-thin text-2xl">* save paper save trees save earth</p>
          <a
            href="/pricing"
            className="p-4 justify-center items-center w-[30%] md:w-[20%] bg-blue-600 text-white shadow-lg no-underline flex"
          >
            Free Trial
          </a>
          <div className="flex justify-start items-center"></div>
        </div>
        <div className="landing_right md:w-[50%] flex z-10  bg-transparent">{<Profile1 />}</div>
      </div>
      <div class=" gap-3  flex px-10 flex-col md:flex-row md:h-40 w-screen mb-20">
        <div class=" flex flex-col p-5 shadow-sm w-full">
          <h1 className="text-2xl font-medium">
            Enhanced Personalization
          </h1>
          <p className="mt-4 text-lg">
            Personalize your information as an when required
          </p>
        </div>
        <div class=" bg-white flex flex-col p-5 shadow-sm w-full">
          <h1 className="text-2xl font-medium">
            Seamless Privacy Control
          </h1>
          <p className="mt-4 text-lg">
            Rest assured that your data and privacy are protected.
          </p>
        </div>
        <div class="bg-white flex flex-col p-5 shadow-sm w-full">
          <h1 className="text-2xl font-medium">
            Access Anytime Anywhere
          </h1>
          <p className="mt-4 text-lg">
            access and share your id effortlessly
          </p>
        </div>
        <div class="bg-white flex flex-col p-5 shadow-sm w-full">
          <h1 className="uppercase text-2xl">Ascent Next</h1>
          <div class="review">
            <Link href="https://ascentindia.dev" class="mt-4 text-lg" >https://ascentindia.dev</Link>
          </div>
          {/* <p className="text-xs text-gray-300">last updated on : 13.7.24</p> */}
        </div>
      </div>
    </div>
  );
}

export default Landing;

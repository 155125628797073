import React, { useEffect, useRef, useState } from "react";
import { Tabs, ColorPicker, Table } from "antd";

import { PlusOutlined } from "@ant-design/icons";


import { Form, Input, Button, Image, Divider, message } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { RegisterUser } from "../../apicalls/users";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import ProfileForm from "./QRProfileForm";
import {
  deleteQrProfile,
  getAllQrProfile,
  getQrProfileById,
} from "../../apicalls/qrprofiles";
import { getDateFormat } from "../../utils/helpers";

function QrProfile() {
  const dispatch = useDispatch();
  const formRef = useRef();

  const navigate = useNavigate();

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profiles, setprofiles] = useState([]);
  const [profileCount, setprofileCount] = useState(0);

  const [show, setshow] = useState(false);

  const urlofcard = process.env.REACT_APP_DOMAIN 

  const [showAlert, setShowAlert] = useState({
    showAlertBox: false,
    setRecordIndex: "",
    setRecordName: "",
  });

  const { user } = useSelector((state) => state.users);

  


  const columns = [
    {
      title: "Actions",
      render: (text, record) => {
        return (
          <div className="flex gap-5">
            <i
              class="ri-pencil-line cursor-pointer"
              onClick={() => {
                setSelectedProfile(record);
                setshow(true);
              }}
            ></i>
            <i
              class="ri-delete-bin-line cursor-pointer"
              onClick={() => {
                setShowAlert({
                  showAlertBox: true,
                  setRecordIndex: record._id,
                  setRecordName: record.name,
                });
                deleteProfileData(record._id);
              }}
            ></i>            
          </div>
        );
      },
    },

    {
      title: "Company",
      dataIndex: "companyname",
    },
    {
      title: "Qr Type",
      dataIndex: "qrtype"
    },
    {
      title: "Qr id",
      dataIndex: "qrid",
    },
    {
      title: "Mobile No",
      dataIndex: "mobile",
    },

    {
      title: "Created By",
      render: (value, record) => record.owner.firstName.toUpperCase(),
    },
    {
      title: "Created On",
      render: (value, record) => getDateFormat(record.createdAt),
    },
    {
      title: "Expired On",
      render: (value, record) => getDateFormat(record.trialenddate),
    },
  ];


  const getData = async () => {
    //  console.log("user id: ", user);
    try {
      dispatch(setLoading(true));

      // const response = await getAllQrProfile({ owner: user._id });

      const response = await getAllQrProfile();

      if (response.success) {
        // console.log("response : " + JSON.stringify(response));
        setprofiles(response.data);
        setprofileCount(response.count);
        // /console.log("Project Data : ", projects);
        dispatch(setLoading(false));
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      message.error(error.message);
      dispatch(setLoading(false));
    }
  };

  // Delete Data

  const deleteProfileData = async (id) => {
    try {
      dispatch(setLoading(true));
      const response = await deleteQrProfile(id);

      if (response.success) {
        dispatch(setLoading(false));
        getData();
        message.success(response.message);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      message.error(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);



  var firstProfile = profiles[0];

  return (
    <div className="mt-10  ml-5 mr-5 flex flex-col lg:mt-20 lg:ml-10 lg:mr-10 ">

      <div className="flex justify-between mb-10 ">
        <h5 className="text-2xl font-normal">QR Profiles</h5>

      

        <Button
          icon={<PlusOutlined />}
          // disabled={user?.roll === "Admin" || profileCount === 1}
          // disabled={user?.roll !== "Admin" && profileCount === 1}
          disabled={user?.roll !== "Admin" && user?.profilecount <= profileCount}
          type="default bg-blue-500 text-white"
          onClick={() => {
            setSelectedProfile(null);
            setshow(true);
          }}
        >
          Add New QR Profile
        </Button>
      </div>

      <div>
        <Table
          columns={columns}
          dataSource={profiles}
          scroll={{
            x: 500,
          }}
        ></Table>
      </div>
      {show && (
        <ProfileForm
          show={show}
          setshow={setshow}
          reloadData={getData}
          profile={selectedProfile}
        />
      )}
    </div>
  );
}

export default QrProfile;

const { apiRequest } = require(".");

export const createQrProfile = async (payload) =>
  apiRequest("post", "/api/qrprofiles/createQrProfile", payload);

export const getAllQrProfile = async (payload) =>
  apiRequest("post", "/api/qrprofiles/getQrProfiles", payload);

export const updateQrProfile = async (payload) =>
  apiRequest("post", "/api/qrprofiles/updateQrProfile", payload);

export const deleteQrProfile = async (objectID) =>
  apiRequest("post", "/api/qrprofiles/deleteQrProfile", { _id: objectID });

// // getProjectByRoll
// export const getProjectbyRoll = async (userId) =>
//   apiRequest("post", "/api/projects/getProjectByRoll", userId);

//getProjectById

export const getQrProfileById = async (projectid) =>
  apiRequest("post", "/api/qrprofiles/getQrProfilebyId", { qrid: projectid });

// // Add Member to Project

// export const addProjectMember = async (data) =>
//   apiRequest("post", "/api/qrprojects/addProjectMember", data);

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import { getProjectbyRoll } from "../../apicalls/profiles";
import { message, List, Card, Divider } from "antd";
import { getDateFormat } from "../../utils/helpers";

function Home() {
  const { user } = useSelector((state) => state.users);
  const [projects, setprojects] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getProjects = async () => {
    try {
      dispatch(setLoading(true));

      const response = await getProjectbyRoll(user._id);

      if (response.success) {
        dispatch(setLoading(false));
        setprojects(response.data);
      } else {
        dispatch(setLoading(false));
        message.error(response.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div>
      <h1 className="text-black text-xl font-weight-500">
        Welcome {user?.firstName} to <span>Next Project ..</span>
      </h1>
      <List
        className="mt-5"
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={projects}
        renderItem={(item) => (
          <List.Item>
            <Card
              onClick={() => {
                navigate("/projectinfo/" + item._id);
              }}
              size="default"
              className="bg-blue-400 text-white cursor-pointer "
              title={item.name}
              headStyle={{
                color: "white",
              }}
            >
              {/* <div> */}
              <p className="uppercase ">{item.desc}</p>

              <h5 className="flex gap-3 mt-2 uppercase text-black">
                OWNER :{" "}
                <span className="text-white">{item.owner.firstName}</span>
              </h5>
              <h5 className="flex gap-3 uppercase mt-2 text-black">
                Status : <span className="text-white">{item.status}</span>
              </h5>
              <h5 className="mt-1 text-black">
                CreatedAt : <span>{getDateFormat(item.createdAt)}</span>
              </h5>
              {/* </div> */}
            </Card>
          </List.Item>
        )}
      />
      ,
    </div>
  );
}

export default Home;

import { apiRequest } from ".";

export const RegisterUser = async (payload) =>
  apiRequest("post", "/api/users/register", payload);

export const LoginUser = async (payload) =>
  apiRequest("post", "/api/users/login", payload);

// export const GetUser = async () => {
//   apiRequest("get", "/api/users/getuser");
// };

export const GetLoggedInUser = async () =>
  apiRequest("get", "/api/users/get-logged-in-user");


// Forgot Password

//local
export const UserForgotPassword = async (payload) =>
  {
  // console.log("Payload : " , payload);  
  // apiRequest("post", "/api/users/forgotuserpassword", payload);
  await apiRequest("post", "/api/users/forgotuserpassword", payload);
  

  }
//  export const UserForgotPassword = async (payload) =>
//    apiRequest("post", "/api/users/forgotpassword", payload);
